import React from 'react'

const Logo = (props) => {
  const { height, width } = props
  return (
    <svg height={height} width={width} fill="#000000" xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 64 64" enableBackground="new 0 0 64 64" xmlSpace="preserve">
      <g display="none">
        <rect x="-306.469" y="-232.764" display="inline" fill="#000000" width="787" height="375.042"></rect>
      </g>
      <g display="none">
        <polyline display="inline" fill="none" stroke="#000000" strokeWidth="2" strokeMiterlimit="10" points="64,63 1,63 1,0  "></polyline>
        <g display="inline">
          <polyline fill="none" stroke="#000000" strokeWidth="2" strokeMiterlimit="10" points="14.122,49.629 24.448,32.076     36.717,37.362 41.408,16.278 60.123,6.612   "></polyline>
          <circle fill="#000000" cx="24.448" cy="32.076" r="3.877"></circle>
          <circle fill="#000000" cx="36.691" cy="37.37" r="3.877"></circle>
          <circle fill="#000000" cx="14.122" cy="49.629" r="3.877"></circle>
          <circle fill="#000000" cx="41.494" cy="16.278" r="3.877"></circle>
          <circle fill="#000000" cx="60.123" cy="6.612" r="3.877"></circle>
        </g>
      </g>
      <g>
        <polygon points="64,64 0,64 0,0 2,0 2,62 64,62  "></polygon>
        <path d="M24.448,30.199c1.035,0,1.877,0.842,1.877,1.877c0,1.035-0.842,1.877-1.877,1.877s-1.877-0.842-1.877-1.877   C22.571,31.041,23.413,30.199,24.448,30.199 M24.448,28.199c-2.141,0-3.877,1.736-3.877,3.877c0,2.141,1.736,3.877,3.877,3.877   s3.877-1.736,3.877-3.877C28.325,29.934,26.589,28.199,24.448,28.199L24.448,28.199z"></path>
        <path d="M34.628,35.373L27.31,32.22c-0.036,0.711-0.325,1.355-0.787,1.838l7.308,3.149C33.871,36.497,34.162,35.854,34.628,35.373z   "></path>
        <path d="M36.691,35.493c1.035,0,1.877,0.842,1.877,1.877s-0.842,1.877-1.877,1.877s-1.877-0.842-1.877-1.877   S35.656,35.493,36.691,35.493 M36.691,33.493c-2.141,0-3.877,1.736-3.877,3.877s1.736,3.877,3.877,3.877s3.877-1.736,3.877-3.877   S38.832,33.493,36.691,33.493L36.691,33.493z"></path>
        <path d="M16.344,47.825l7.602-12.923c-0.693-0.123-1.293-0.497-1.72-1.022l-7.601,12.923C15.318,46.926,15.917,47.3,16.344,47.825z   "></path>
        <path d="M14.122,47.752c1.035,0,1.877,0.842,1.877,1.877s-0.842,1.877-1.877,1.877s-1.877-0.842-1.877-1.877   S13.088,47.752,14.122,47.752 M14.122,45.752c-2.141,0-3.877,1.736-3.877,3.877s1.736,3.877,3.877,3.877s3.877-1.736,3.877-3.877   S16.263,45.752,14.122,45.752L14.122,45.752z"></path>
        <path d="M41.494,19.154c-0.608,0-1.171-0.192-1.636-0.516L36.323,34.53c0.122-0.016,0.242-0.037,0.368-0.037   c0.585,0,1.128,0.178,1.583,0.479L41.8,19.123C41.698,19.134,41.599,19.154,41.494,19.154z"></path>
        <path d="M41.494,14.401c1.035,0,1.877,0.842,1.877,1.877s-0.842,1.877-1.877,1.877s-1.877-0.842-1.877-1.877   S40.459,14.401,41.494,14.401 M41.494,12.401c-2.141,0-3.877,1.736-3.877,3.877s1.736,3.877,3.877,3.877s3.877-1.736,3.877-3.877   S43.635,12.401,41.494,12.401L41.494,12.401z"></path>
        <path d="M57.281,6.955L43.388,14.13c0.506,0.447,0.849,1.063,0.944,1.764l13.865-7.161C57.698,8.28,57.366,7.657,57.281,6.955z"></path>
        <path d="M60.123,4.735C61.158,4.735,62,5.577,62,6.612c0,1.035-0.842,1.877-1.877,1.877s-1.877-0.842-1.877-1.877   C58.246,5.577,59.088,4.735,60.123,4.735 M60.123,2.735c-2.141,0-3.877,1.736-3.877,3.877c0,2.141,1.736,3.877,3.877,3.877   S64,8.753,64,6.612C64,4.471,62.264,2.735,60.123,2.735L60.123,2.735z"></path>
      </g>
    </svg>
  )
}

export default Logo
