module.exports = [{
      plugin: require('/Users/david/Workspace/repos/analytics/site/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/david/Workspace/repos/analytics/site/node_modules/gatsby-plugin-analytics/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/david/Workspace/repos/analytics/site/node_modules/gatsby-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":["gatsby-remark-autolink-headers",{"resolve":"gatsby-remark-copy-linked-files","options":{"ignoreFileExtensions":[]}},{"resolve":"gatsby-remark-prismjs","options":{"showLineNumbers":true}},{"resolve":"gatsby-remark-check-links","options":{}}]},
    }]
